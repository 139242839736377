.infoWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 2px;
    gap: 2px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    height: 100%;
  }
  
  .icon {
    display: flex;
    width: 14px;
    height: 14px;
    align-items: center;
    justify-content: center;
  }
  